@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/Montserrat-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "Garamond";
  src: url("../public/EBGaramond12-Regular.otf");
  font-display: swap;
}

body::-webkit-scrollbar {
  width: 10px;
               
}
body::-webkit-scrollbar-track {
  background: #2b2b2b;
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(207, 207, 207);
}


html, body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

.navLink a.active {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: #ec4899;
  text-decoration-thickness: 2px;
  animation: navLinkLoad 0.7s;
}

.hideMenuNav {
  display: none;
}



.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  /* height: 30%; */
  top: 0;
  margin-top: 9rem;
  left: 0;
  background: #e4e4e7;
  color: black;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition-duration: 1000ms;
}

.dark .showMenuNav {
  background: #27272a;
  color: white;
}

@keyframes navLinkLoad {
  0% {
    text-underline-offset: 8px;
  }
  100% {
    text-underline-offset: 4px;
  }
}

a, button, summary {
  @apply cursor-linkBlack;
  @apply dark:cursor-linkWhite;
}

.rotated180 {
  transition-delay: 300ms;
  transition-timing-function: ease-in-out;
}

@keyframes rotateScroller {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180);
  }
}